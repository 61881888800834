.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(50px + 10vmin);
  color: white;
}

.App-header p {
  margin: 0;
}


.App.no-color{
  background-color: #282c34 !important;
}


.pos {
  font-size: 40px;
}


